
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import { AUTH_STORE_NAME } from '@/store/auth.store';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AccountRepository from "@/api/repositories/AccountRepository";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    LogoComponent: () => import(
        /* webpackChunkName: "LogoComponent" */
        '@/components/Logo.component.vue'
        ),
  }
})
export default class ResendVerificationComponent extends mixins(ErrorMessageHandlerMixin) {
  /**
   * Initial Email Address
   */
  @Prop({required: true})
  public email!: string;

  private isLoading: boolean = false;

  /**
   * Successful Sent State bool
   * Shows / Hides the Email field
   * and
   * @private
   */
  private successful: boolean = false;
  /**
   * Dismiss Event Sender
   * For closing the Dialog on Button press
   * @private
   */
  private dismiss() {
    // Reset Success state
    this.successful = false;
    this.$emit("close");
  }

  /**
   * Method for Requesting a Password Reset Email
   * @private
   */
  private async requestPassword() {
    // Try to reset the password with the current email
    try {
      this.isLoading = true;

      // Initiate Password Reset
      await AccountRepository.resendVerification(this.email!);

      // Show Success Message
      this.successful = true;
    } catch (e) {
      // Not Successful
      this.successful = false;

      // Handle any incoming errors
      this.$handleError(e, () => {
        switch (e.status) {
          case 404:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.ACCOUNT_VERIFICATION_FAILED');
            break;
          case 422:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.ACCOUNT_VERIFICATION_ALREADY_VERIFIED');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      });
    } finally {
      this.isLoading = false;
    }
  }
}
